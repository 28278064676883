import axiosHttpClient from '@/helpers/axiosHttpClient.js'
import doNothing from '@/helpers/api/doNothing.js'
import handleApiError from '@/helpers/logging/handleApiError.js'
import Logger from '@/helpers/logging/Logger.js'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { useFeaturesStore } from '@/stores/useFeaturesStore.js'
import { useModulesStore } from '@/stores/useModulesStore.js'
import { usePermissionsStore } from '@/stores/usePermissionsStore.js'
import { usePreferencesStore } from '@/stores/usePreferencesStore.js'
import { useSettingsStore } from '@/stores/useSettingsStore.js'
import { useStorage } from '@vueuse/core'

export const useAuthorizationStore = defineStore('authorization', () => {
	// state
	const refreshTime = 10 // time in seconds before the session expires when we try to refresh the token
	const authorization = useStorage('authorization', { control_measures: [] }, localStorage)
	const loading = ref(false)
	const expires_at = useStorage('authorization-expires-at', 0, localStorage)

	// getters
	const accessToken = computed(() => authorization.value?.access_token ?? false)
	const user = computed(() => authorization.value?.user ?? {})
	const expires = computed(() => expires_at?.value ?? 0)

	// calculate whether the session is still active since getters are cached
	function isSessionActive() {
		Logger.debug('sessionActive')
		if (localStorage['authorization-expires-at'] === '0') {
			Logger.debug('sessionActive: no localStorage session')
			return false
		}
		if (authorization.value?.access_token === null) {
			Logger.debug('sessionActive: no access token')
			return false
		}
		if ((expires_at?.value ?? 0) < (Date.now() / 1000)) {
			Logger.debug('sessionActive: session expired')
			return false
		}
		return true
	}

	// methods
	/**
	 * Login the user
	 * @param {string} username
	 * @param {string} password
	 * @returns {Promise} A promise to act upon
	 */
	async function login(username, password) {
		Logger.debug('authStore.login')
		const credentials = { username, password }

		loading.value = true
		await axiosHttpClient
			.post('auth/login', credentials)
			.then(response => {
				Logger.debug('authStore.login: Login was successful')
				const now = Math.floor(new Date().getTime() / 1000)
				authorization.value = response.data
				expires_at.value = now + response.data.expires_in
			})

		Logger.debug('authStore.login: Getting auth/me')
		await axiosHttpClient
			.get('auth/me')
			.then(response => { authorization.value.user = response?.data?.data ?? {} })
			.catch(handleApiError)

		loading.value = false
	}

	/**
	 * Logout the user
	 */
	async function logout() {
		Logger.debug('useAuthStore:logout: Received a logout instruction')
		// Try this, but it's not necessary if the session has already run out
		await axiosHttpClient.post('auth/logout').catch(doNothing)

		remove()
		useFeaturesStore().remove()
		useModulesStore().remove()
		usePermissionsStore().remove()
		usePreferencesStore().remove()
		useSettingsStore().remove()
	}

	/**
	 * Refresh the token
	 * @returns {Promise} A promise to act upon
	 */
	function refresh() {
		loading.value = true
		return axiosHttpClient
			.post('auth/refresh')
			.then(response => {
				const data = response?.data ?? {}
				const now = new Date().getTime() / 1000
				authorization.value.access_token = data?.access_token
				authorization.value.token_type = data?.token_type
				authorization.value.expires_in = data?.expires_in
				expires_at.value = now + data.expires_in
			})
			.catch(handleApiError)
			.finally(() => { loading.value = false })
	}

	/**
	 * Remove data from localStorage and the store
	 */
	function remove() {
		loading.value = false
		authorization.value = {}
		expires_at.value = 0
	}

	/**
	 * @param {string} type
	 * @returns {boolean} whether the user is of this type
	 */
	function isOfUserType(type) {
		return authorization.value?.user?.user_type === type
	}

	return {
		accessToken,
		authorization,
		expires,
		expires_at,
		isOfUserType,
		isSessionActive,
		loading,
		login,
		logout,
		refresh,
		refreshTime,
		remove,
		user,
	}
})
