import axios from 'axios'
import FileSaver from 'file-saver'
import httpStatus from '@/helpers/httpStatus.js'
import Logger from '@/helpers/logging/Logger.js'
import showError from '@/helpers/alertMessages/showError.js'
import { defineAsyncComponent } from 'vue'
import { getHeaders } from '@/helpers/oauth.js'
import { i18n } from '@/helpers/i18n.js'
import { showModal } from '@/helpers/modals.js'
import { useAuthorizationStore } from '@/stores/useAuthStore.js'

const SessionExpiredModal = defineAsyncComponent(() => import('@/modules/auth/components/SessionExpiredModal.vue'))

const axiosHttpClient = axios.create({
	baseURL: `${import.meta.env.VITE_API_URL}/api/v1`
})

/**
 * @param {object} cfg
 * @param {string} [cfg.method]
 * @param {string} [cfg.url]
 * @param {object} [cfg.data]
 * @param {object} [cfg.config]
 * @param {string|null} [cfg.fileName]
 * @returns {Promise} A promise with a file
 */
axiosHttpClient.getFile = ({
	method = 'GET',
	url = 'does-not-exist',
	data = {},
	config = { responseType: 'arraybuffer' },
	fileName = null
} = {}) => {
	return new Promise((resolve, reject) =>
		axiosHttpClient
			.request({ method, url, data, ...config })
			.then(response => {
				if (response?.status === httpStatus.OK) {
					const possibleFileNames = [
						fileName,
						response?.headers?.['x-content-filename'],
						response?.headers?.['content-disposition']?.split('filename=')?.[1]?.split(';')?.[0].replaceAll('"', ''),
						'filename.extension'
					]
					const dynamicFileName = possibleFileNames.filter(Boolean)[0]
					FileSaver.saveAs(new File([ response.data ], dynamicFileName))
					resolve()
				} else {
					reject('File status is not OK')
				}
			})
			.catch(e => reject(e)))
}

axiosHttpClient.postFile = (url, file) => {
	return new Promise((resolve, reject) => {
		const fd = new FormData()
		fd.append('file', file)
		axiosHttpClient
			.post(url, fd)
			.then(response => {
				if ([ httpStatus.OK, httpStatus.CREATED ].includes(response?.status)) {
					resolve(response)
				} else {
					reject('File status is not OK')
				}
			})
			.catch(e => reject(e))
	})
}

axiosHttpClient.interceptors.request.use(config => {
	const auth = useAuthorizationStore()
	const headers = getHeaders(auth.accessToken)
	config.headers = { ...headers, ...config.headers }
	return config
})

let sessionOverFlag = false
axiosHttpClient.interceptors.response.use(function (response) {
	// Any status code that lie within the range of 2xx cause this function to trigger
	// Do something with response data
	return response
}, async function (error) {
	Logger.debug('axiosHttpClient:onRejected')
	// Any status codes that falls outside the range of 2xx cause this function to trigger
	// Do something with response error

	if (error?.response?.status === httpStatus.TOO_MANY_REQUESTS) {
		await showError(i18n.global.t('common.tooManyRequests'))
	}

	if (error?.response?.status === httpStatus.UNAUTHORIZED) {
		Logger.debug('axiosHttpClient intercepted an unauthorized response')

		if (!error.request.responseURL.endsWith('auth/login') && !error.request.responseURL.endsWith('auth/logout')) {
			if (!sessionOverFlag) {
				sessionOverFlag = true
				const auth = useAuthorizationStore()
				await auth.logout()
				await showModal(SessionExpiredModal)
				sessionOverFlag = false
				return Promise.reject()
			}
			return Promise.reject()
		}
	}
	return Promise.reject(error)
})

export default axiosHttpClient
